@import "../utils/colors.scss";

.navbar{
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    .col{
        display: flex;
        justify-content:space-between;
        align-items: center;
        .collapseble-button{
            display: none;
            margin-left: 10rem;
        }
    }
    nav{
        .links{
            ul{
                display: flex;
                list-style-type: none;
                align-items: center;
                justify-content: center;
                margin: 0.3rem 2rem;
                color:$lightFontColor;
                li{
                    a{
                        text-decoration: none;
                        font-size: 1.2rem;
                        font-weight: 700;
                        color: $blueColor;
                        &:hover{
                            color: $pinkColor;
                        }
                        

                    }
                    margin: 0 1rem;
                    text-transform: uppercase;
                }
             
            }
        }
    }
}
@media screen and (min-width:320px) and (max-width:768px) {
.navbar.active{
    nav{
        display: block;
    }
}    
.navbar{
    flex-direction:column;
    justify-content: flex-start;
    .col{
       
        .collapseble-button{
            display: block;
            font-size: 4rem;
           
        }
    }
    nav{
        display: none;
        .links{
            ul{
                flex-direction: column;
                align-items: center;
                margin: 0.3rem 0rem;
               li{
                   margin: 2rem 0 0 0;
                   &:first-of-type{
                       margin-top: 4rem;
                   }
                   a{
                       font-weight: bolder;
                       font-size: large;
                     
                   }
               } 
            }
        }
    }
}
}

