@import "../utils/colors.scss";
.logo {
  margin-bottom: -10rem;
  cursor: pointer;

  img {
    width: 15rem;
    position: relative;
    top: -5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .logo {
    cursor: pointer;
    margin-bottom: 1rem;

    img {
      width: 15rem;
      height: 15rem;
      top: 1rem;
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .logo {
    cursor: pointer;

    img {
      width: 17rem;
      height: 17rem;
      top: 0rem;
    }
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .logo {
        margin-bottom: -2rem;
      } 
}
@media screen and  (min-width:1024px) and (max-width: 1366px){
    .logo {
        margin-bottom: -10rem;
      }

}