@import "../utils/colors.scss";

.footer-container {
  background-color: #333335;
  color: white;
  min-height: 55vh;
  height: fit-content;
  padding: 8rem 0;
  .main-container {
    margin: 0 10%;
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .quick-links {
      margin: 0 2rem;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 1rem 0;
        a {
          color: #b5b8b8;
          text-decoration: none;
          &:hover {
            color: white;
          }
        }
      }
    }
    .news-letter {
      p {
        margin: 2rem 0.5rem 2rem 0;
      }
      .mail {
        width: 75%;
        position: relative;
        input {
          margin: 0px;
          padding: 0.1rem 1rem;
          width: 100%;
          outline: none;
          height: 2.4rem;
          border-radius: 0.3rem;
          border: none;
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          border: none;
          height: 2.6rem;
          cursor: pointer;
          color: white;
          background-color: $pinkColor;
          transform: translateX(33px);
        }
      }
    }
    .touch {
      h3 {
        margin-bottom: 1rem;
      }
      .touch-section {
        margin-bottom: 1rem;
        display: flex;
      }
      .icon {
        display: flex;
        justify-content: center;
        background-color: #2c2c31;
        border-radius: 0.3rem;
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
      }
      svg {
        font-size: 1.5rem;
        padding-top: 0.5rem;
      }
    }
    .details {
      .detail-content {
        a {
          text-decoration: none;
          color: #b5b8b8;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  .copyright {
    margin: 5% 10% 0 10%;
    display: flex;
    justify-content: space-between;
    border-top: #b5b8b8 1px solid;
    .copy {
      color: #b5b8b8;
    }
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        width: 2rem;
        background-color: #2c2c31;
        border-radius: 0.3rem;
        margin-left: 0.3rem;
        transition: 0.5s ease-in-out;
       
        &:hover {
          background-color: white;
          svg {
            color: #2c2c31;
           
          }
        }
        svg {
          font-size: 1.2rem;
          color: white;
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .footer-container {
  
    .main-container {
      grid-template-columns: 1fr;
      h3 {
        font-size: 2.4rem;
      }
      ul {
        margin-bottom: 2rem;
        li {
          font-size: 1.5rem;
        }
      }
      .news-letter {
        margin-bottom: 2rem;
        p {
          font-size: 1.7rem;
        }
        .mail {
          input {
            height: 4.5rem;
          }
          button {
            height: 4.7rem;
          }
        }
      }
      .touch {
        .touch-section {
          .icon {
            height: 5rem;
            width: 5rem;
            svg {
              font-size: 2rem;
            }
          }
        }
        .details {
          .detail-name {
            font-size: medium;
          }
          .detail-content {
            font-size: small;
          }
        }
      }
    }
    .copyright{
        border-top-width: 0.5rem;
        flex-direction: column;
        .copy{
            p{
                font-size: 2rem;
            }
        }
        .icons{
            margin-bottom: 1rem;
            .icon{
                height: 4rem;
                width: 4rem;
                margin-right: 1rem;
                svg{
                    font-size: 2rem;
                }
            }
        }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px){
    .footer-container{
        min-height: 30vh;
        .main-container{
            .news-letter{
                margin-bottom: 2rem;
                p {
                  font-size: 1.7rem;
                }
                .mail {
                    input {
                      height: 4.5rem;
                    }
                    button {
                      height: 4.7rem;
                    }
                  }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width:1024px ){
  .footer-container{
    
    .main-container{
        .news-letter{
            margin-bottom: 2rem;
            p {
              font-size: 1.7rem;
            }
            .mail {
                input {
                  height: 4.5rem;
                }
                button {
                  height: 4.7rem;
                }
              }
        }
    }

}

}
@media screen  and (min-width:1024px)and (max-width:1366px){
    .footer-container{
       min-height: 20vh;
       .touch-section{
         .detail-content{
           font-size: 0.7rem;
         
         }
       }
    }
}