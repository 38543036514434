@import "../utils/colors.scss";
.main-container {
    margin: 0 10%;
  
    .container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .content{
        margin-top: 3.4rem;
        margin-right:3rem ;
        display: flex;
        flex-direction: column;
        h1{
            font-size: 3rem;
            color:$headingColor;
        }
        p{
            font-size: 1.4rem;
            color:$lightFontColor;
            line-height: 1.9rem;

        }
        .button-container{
            display: flex;
            button{
                margin-right: 1rem;
            }
        }
    }
    .image{
        img{
            height: 35rem;
            width:35rem;
        }
    }
}
@media screen and (min-width:320px) and (max-width:480px){
    .main-container{
        .container{
            grid-template-columns: 1fr;
            .content{
                margin-right: 0;
                h1{
                    font-size: 4rem;
                }
                p{
                    font-size: 2rem;
                    line-height: 2.5rem;
                }
            }
            
        }
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .main-container{
       
        
        .container{
         
            .image{
                img{
                   position: relative;
                   left: 8rem;
                   top:-2rem
                  
                }
            }
        }
    }
}
@media screen and  (min-width:1024px)and (max-width: 1366px){
    .main-container{
        margin-bottom: 10%;
        
        .container{
           .content{
                h1{
                    font-size: 2rem;
                    line-height: 2rem;
                }
                p{
                    font-size: 1.4rem;
                    line-height: 1.5rem;
                }
            }
            .image{
                img{
                   position: relative;
                   left: 7rem;
                  
                }
            }
        }
    }
}